<template>
  <div class="loginmodal">
    <div class="modal-content">
      <div class="loginclose" @click="$emit('close')"><img src="@/assets/img/icon_close.svg" /></div>
      <!-- <h2>{{ $t('front.login.greeting') }}</h2> -->
      <h1 class="login-popup-title"><img src="@/assets/img/play_peanut.svg" /></h1>
      <ui-input :className="'logininput'" :type="'text'" :placeholder="$t('front.common.memId')" @input="getMemId"/>
      <ui-input :className="'logininput'" :type="'password'" :placeholder="$t('front.common.password')" @input="getMemPass" @keyup.enter="onSubmit"/>

      <section class="captcha-box">
        <VueClientRecaptcha
            :value="inputValue"
            :count="4"
            chars="12345"
            :hideLines="true"
            custom-text-color="#af965c"
            @getCode="getCaptchaCode"
            @isValid="checkValidCaptcha"
        >
        <template #icon>
            <span></span>
          </template>
        </VueClientRecaptcha>
        <input
            v-model="inputValue"
            placeholder="자동입력방지코드"
            class="input"
            type="text"
            @keyup.enter="onSubmit"
        />
      </section>
      <!--a class="finds">{{$t('front.common.memId')}}/{{$t('front.common.password')}} {{$t('front.common.find')}}</a-->
      <ui-button :className="'loginnbtn on'" :text="$t('front.login.loginButton')"  @click.prevent="onSubmit" />
      <p class="jointxt">{{ $t('front.login.notFoundId') }}<ui-button :className="'joinnbtn'" :text="$t('front.common.signup')" @click="$emit('close', 'join')"/></p>
    </div>
  </div>
</template>

<script>

import UiButton from '@/components/ui/UiButton'
import UiInput from '@/components/ui/UiInput'

import { signIn } from '@/api/member'
import { setAuthData } from '@/libs/auth-helper'
import store from '@/store'

import VueClientRecaptcha from 'vue-client-recaptcha'

export default {
  name: 'Signin',
  components: {
    UiButton,
    UiInput,
    VueClientRecaptcha
  },
  computed: {
    canSubmit () {
      return this.model.memId && this.model.memPass
    }
  },
  data () {
    return {
      model: {
        memId: '',
        memPass: ''
      },
      data: {
        captchaCode: null,
        isValid: false
      },
      inputValue: null
    }
  },
  methods: {
    getCaptchaCode (value) {
      this.data.captchaCode = value
    },
    checkValidCaptcha (value) {
      this.data.isValid = value
    },
    getMemId (value) {
      this.model.memId = value
    },
    getMemPass (value) {
      this.model.memPass = value
    },
    setReloadCaptcha () {
      setTimeout(() => {
        if (document.querySelector('.vue_client_recaptcha_icon')) {
          document.querySelector('.vue_client_recaptcha_icon').click()
        }
      }, 500)
    },
    async onSubmit () {
      let reloadCaptcha = false
      if (!this.data.isValid) {
        reloadCaptcha = true
        await this.onAlert('warningart', '자동 입력 방지 코드를 확인해주세요.')
      }

      if (this.model.memId === '') {
        reloadCaptcha = true
        this.onAlert('warningart', 'front.signin.emptyMemId')
      }
      if (this.model.memPass === '') {
        reloadCaptcha = true
        this.onAlert('warningart', 'front.signin.emptyMemPass')
      }

      if (!this.canSubmit) {
        reloadCaptcha = true
      }

      if (reloadCaptcha) {
        this.setReloadCaptcha()
        return false
      }

      /*
      * api/member.js 참고
      */
      signIn(this.model).then(async response => {
        const result = response.data
        const data = result.data

        if (result.resultCode === '0') {
          setAuthData(data)
          store.commit('setUserData', data.member)
          store.dispatch('storeCommonCode').then(commonCode => {
            if (data.msgInfo) {
              store.dispatch('storeUserUnReadMessageCount', data.msgInfo.newMsg)
            }
            this.$emit('close', 'signin')
          })

          // setTimeout(function () {
          //   this.$emit('close', 'signin')
          // }.bind(this), 100)
        } else if (result.resultCode === 'U001' || result.resultCode === 'LX02') {
          await this.onCheck('err.' + result.resultCode)
          this.setReloadCaptcha()
        } else {
          // const confirm = await this.onConfirm('api.' + result.resultCode)
          // if (confirm) {
          //   this.$emit('signup')
          // }
          console.log(result.resultCode)
          await this.onCheck('로그인정보가 올바르지 않습니다. 확인해주세요.')
          this.setReloadCaptcha()
        }
      }).catch(err => {
        console.error('login err : ', err.message)

        if (err.message === 'no member') {
          // alert('로그인정보가 올바르지 않습니다. 확인해주세요.')
          this.onCheck('로그인정보가 올바르지 않습니다. 확인해주세요.')
        } else {
          alert(err.message)
        }
      })
    }
  }
}
</script>

<style scoped>
.captcha-box { margin-top: 15px; }
.captcha-box .vue_client_recaptcha {
  background-color: #0f0f0f;
  width: 200px;
  display: inline-block;
  border-radius: 10px;
  border: 1px solid #707070;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 16%);
  position: relative;
}

/deep/ .captcha-box .vue_client_recaptcha .vue_client_recaptcha_icon {
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 10px;

}
/deep/ .captcha-box .vue_client_recaptcha .vue_client_recaptcha_icon span {
  display: inline-block;
  background-image: url(../../assets/img/icon_reload.svg);
  width: 30px;
  height: 30px;
}
.captcha-box .input {
  position: relative;
  width: 50%;
  height: 50px;
  text-align: center;
  font-size: 20px;
  letter-spacing: -0.025em;
  border:0;
  background: none;
  border-bottom: solid 1px #707070;
  color: #fff;
  vertical-align: top;
  margin-left: 10px;
}
.captcha-box .input:focus {border-bottom: solid 1px #af965c;}
.captcha-box .input:focus::placeholder {color: #fff;}
</style>

<style scoped src="@/styles/header.css"></style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
