export const exchange = {
  name: 'exchangeMain',
  path: '/exchangeMain',
  component: () => import('@/views_mobile/member/exchange'),
  redirect: {
    name: 'exchange'
  },
  meta: {
    layout: 'mobile-default',
    text: '출금',
    gnb: false,
    gnbMenu: 'exchange'
  },
  children: [
    {
      name: 'exchange',
      path: '/exchange',
      component: () => import('@/views_mobile/member/exchange/exchange'),
      meta: {
        layout: 'mobile-default',
        text: '출금',
        gnb: false,
        gnbMenu: 'exchange'
      }
    }
  ]
}
